import { useEffect, useState } from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import CompanyInformation from '../../components/ProfilePage/CompanyInformation';
import { ProductFilterProvider } from '../../contexts/ProductFilterContext';
import LegalRepresentative from '../../components/ProfilePage/LegalRepresentive';
import BankInformation from '../../components/ProfilePage/BankInformation';
import Security from '../../components/ProfilePage/Security';
import { Accordion, AccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import './styles.scss';
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Profile() {
  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


  const [screenSize, setScreenSize] = useState<any>();


  useEffect(() => {
    setScreenSize(getCurrentDimension());
  }, []);


  function getCurrentDimension() {
    let width = null;
    let height = null;
    if (typeof window !== "undefined") {
      width = window.innerWidth;
      height = window.innerHeight;
    }
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <ProductFilterProvider>
      <div className="container p-2 p-sm-3 p-md-4 add-product ">
        <div className="container sticky-header bg-white pt-2 pb-0 pb-md-1 mt-0">
          <div className="row">
            <div className="col-lg-3 col-12">
              <h2>Profile</h2>
            </div>
            <div className="col-lg-9 col-12"></div>
          </div>
        </div>

        {screenSize?.width > 576 ? (
          <div className="product-tab mt-3 p-3 p-md-1">

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Company Information" {...a11yProps(0)} />
                <Tab label="Legal representative" {...a11yProps(1)} />
                <Tab label="Bank information" {...a11yProps(2)} />
                <Tab label="Security" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CompanyInformation />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LegalRepresentative />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <BankInformation />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Security />
            </TabPanel>
          </div>
        )
          : (
            <div className="product-accrodin m-0 px-2">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Company Information
                </AccordionSummary>
                <AccordionDetails>
                  <CompanyInformation />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Legal representative
                </AccordionSummary>
                <AccordionDetails>
                  <LegalRepresentative />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Bank information
                </AccordionSummary>
                <AccordionDetails>
                  <BankInformation />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Security
                </AccordionSummary>
                <AccordionDetails>
                  <Security />
                </AccordionDetails>
              </Accordion>
            </div>
          )}

        <div className="pt-3 border-top w-100" >
          <button className="float-right white-button" style={{ width: "147px" }}>
            Cancel
          </button>
          <button className="float-right black-button" style={{ width: "147px", marginRight: '24px' }}>
            Save
          </button>
        </div>
      </div>
    </ProductFilterProvider>
  );


}
export default Profile;