import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useContext } from "react";
import { ProductFilter } from "../../contexts/ProductFilterContext";
import Autocomplete from "@mui/material/Autocomplete";

export default function LegalRepresentative() {
    const { filterItems } = useContext(ProductFilter);

  return (
    <table className="add-table no-border">
      <tbody>
        <tr className="no-border">
          <td>Full Name</td>
          <td>
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter FirstName ..."
                  aria-label="Enter FirstName ..."
                  aria-describedby="basic-addon2"
                />
              </div>
              <div className="col-6 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter LastName ..."
                  aria-label="Enter LastName ..."
                  aria-describedby="basic-addon2"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr className="no-border">
          <td>Date of Birth</td>
          <td>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker", "DatePicker", "DatePicker"]}
              >
                <DatePicker
                  className="form-control"
                  slotProps={{ textField: { size: "small" } }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </td>
        </tr>
        <tr className="no-border">
            <td>Email</td>
            <td>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email ..."
                  aria-label="Enter Email ..."
                  aria-describedby="basic-addon2"
                />
            </td>
        </tr>
        <tr className="no-border">
            <td>PHone Number</td>
            <td>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Phone Number ..."
                  aria-label="Enter Phone Number ..."
                  aria-describedby="basic-addon2"
                />
            </td>
        </tr>
        <tr className="no-border">
            <td>Mobile Number</td>
            <td>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Mobile Number ..."
                  aria-label="Enter Mobile Number ..."
                  aria-describedby="basic-addon2"
                />
            </td>
        </tr>
        <tr className="no-border">
            <td>Nationality</td>
            <td>
            <Autocomplete
              multiple={false}
              id="custom-input-demo"
              options={filterItems.locations}
              renderInput={(params) => (
                <div className="input-group" ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    type="text"
                    className="form-control"
                    placeholder="Enter Nationality ..."
                    aria-label="Enter Case Diameter ..."
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text" id="basic-addon2">
                      <span className=" input-group-img"></span>
                    </div>
                  </div>
                </div>
              )}
            />
            </td>
        </tr>
        <tr  className="no-border">
            <td>Residence country</td>
            <td>
            <Autocomplete
              multiple={false}
              id="custom-input-demo"
              options={filterItems.locations}
              renderInput={(params) => (
                <div className="input-group" ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    type="text"
                    className="form-control"
                    placeholder="Enter Residence country ..."
                    aria-label="Enter Case Diameter ..."
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text" id="basic-addon2">
                      <span className=" input-group-img"></span>
                    </div>
                  </div>
                </div>
              )}
            />
            </td>
            </tr>
      </tbody>
    </table>
  );
}
