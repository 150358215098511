export default function Security () {
    return(
        <table className="add-table no-border">
      <tbody>
        <tr className="no-border">
          <td>Current Password</td>
          <td>
            <input
            type="password"
              className="form-control"
              placeholder="Enter Password ..."
              aria-label="Enter Password ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>New Password</td>
          <td>
            <input
            type="password"
              className="form-control"
              placeholder="Enter Password ..."
              aria-label="Enter Password ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>Confirm New Password</td>
          <td>
            <input
            type="password"
              className="form-control"
              placeholder="Enter Password ..."
              aria-label="Enter Password ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        </tbody>
        </table>
    )
}