import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { useContext } from "react";
import { ProductFilter } from "../../contexts/ProductFilterContext";

export default function CompanyInformation() {
  const { filterItems } = useContext(ProductFilter);

  return (
    <table className="add-table no-border">
      <tbody>
        <tr className="no-border ">
          <td>Business logo</td>
          <td className="profile-logo-td">
              <img
              className="rounded-circle border  "
              src="/images/logo-sample.png"
              width="104"
              height="104"
              alt="profile"
            />
            <div className="company-information-logo">
            <button className="white-button " style={{ width: "147px", marginRight: '24px' }}>
              <AddIcon />
              Upload
            </button>
            <button className="white-button " style={{ width: "147px" }}>
              <DeleteOutlineIcon />
              Delete
            </button> 
            </div>
            
          </td>
        </tr>
        <tr  className="no-border">
          <td>Business name</td>
          <td>
            <input
            value={'Zimon'}
              type="text"
              disabled
              className="form-control disable-input"
              placeholder="Enter Business name ..."
              aria-label="Enter Business name ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr  className="no-border">
          <td>Email</td>
          <td>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Email ..."
              aria-label="Enter Email ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr  className="no-border">
          <td>Website</td>
          <td>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Website ..."
              aria-label="Enter Website ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr  className="no-border">
          <td>Phone Number</td>
          <td>
            <input
              type="number"
              className="form-control"
              placeholder="Enter Phone Number ..."
              aria-label="Enter Phone Number ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="border-bottom" >
          <td>Business license</td>
          <td>
            <button className="white-button" style={{ width: "147px" }}>
              <AddIcon />
              Upload
            </button>
            <div className="green-text pt-2">
                <CheckCircleIcon />
                Confirmed
            </div>
          </td>
        </tr>
        <tr  className="no-border">
          <td>Address line 1</td>
          <td>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Street address ..."
              aria-label="Enter Street address ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr  className="no-border">
          <td>Address line 2</td>
          <td>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Building No. ..."
              aria-label="Enter Building No. ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr  className="no-border">
          <td>Country</td>
          <td>
            <Autocomplete
              multiple={false}
              id="custom-input-demo"
              options={filterItems.locations}
              renderInput={(params) => (
                <div className="input-group" ref={params.InputProps.ref}>
                  <input
                    {...params.inputProps}
                    type="text"
                    className="form-control"
                    placeholder="Enter Country ..."
                    aria-label="Enter Case Diameter ..."
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text" id="basic-addon2">
                      <span className=" input-group-img"></span>
                    </div>
                  </div>
                </div>
              )}
            />
          </td>
        </tr>
        <tr  className="no-border">
          <td>Town / City</td>
          <td>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Town / City ..."
              aria-label="Enter Town / City ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border" >
          <td>State & Zip code</td>
          <td>
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter State ..."
                  aria-label="Enter State ..."
                  aria-describedby="basic-addon2"
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Zip code ..."
                  aria-label="Enter Zip code ..."
                  aria-describedby="basic-addon2"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      
    </table>
  );
}
