import React, { ReactNode, useEffect, useState } from 'react';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';

// project import
import useConfig from './../hooks/useConfig';
import { I18n } from './../types/config';
import config from '../config';

// load locales files
const loadLocaleData = (locale: I18n) => {
  console.log('locale', locale);

  switch (locale) {
    case 'de':
      return import('./../utils/locales/de.json');
    case 'fr':
      return import('./../utils/locales/fr.json');
    case 'ro':
      return import('./../utils/locales/ro.json');
    case 'zh':
      return import('./../utils/locales/zh.json');
    case 'en':
    default:
      return import('./../utils/locales/en.json');
  }
};

// ==============================|| LOCALIZATION ||============================== //

interface Props {
  children: ReactNode;
}

const Locales = ({ children }: Props) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

  useEffect(() => {
    loadLocaleData(i18n).then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
      fetch(`${config.baseUrl}api/v1/dealer/profile/TR1JSCM`)
        .then(
          (response) => response.json()
        ).then((data) => {
          if (data) {
            loadLocaleData(data.languageCode.toLowerCase()).then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
              setMessages(d.default);
            })
          }
        })
    });
  }, []);

  useEffect(() => {
    loadLocaleData(i18n).then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
      setMessages(d.default);
    });
  }, [i18n]);


  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

export default Locales;
