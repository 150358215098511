export default function BankInformation() {
  return (
    <table className="add-table no-border">
      <tbody>
        <tr className="no-border">
          <td>Bank Name</td>
          <td>
            <input

              className="form-control"
              placeholder="Enter Bank name ..."
              aria-label="Enter Bank name ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>Account holder</td>
          <td>
            <input

              className="form-control"
              placeholder="Enter Account holder ..."
              aria-label="Enter Account holder ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>IBAN</td>
          <td>
            <input

              className="form-control"
              placeholder="Enter IBAN ..."
              aria-label="Enter IBAN ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>BIC</td>
          <td>
            <input

              className="form-control"
              placeholder="Enter BIC ..."
              aria-label="Enter BIC ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
