import React, { useEffect, useMemo } from 'react';
import ScrollTop from './components/ScrollTop';
import Routes from './routes';
import Locales from './components/Locales';
import { CssBaseline, Theme, ThemeOptions, ThemeProvider, createTheme } from '@mui/material';
import useConfig from './hooks/useConfig';

// add bootstrap css 
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.rtl.css';

// toastify
import "react-toastify/dist/ReactToastify.css";

// own css files here
import "./assets/styles/custom.scss";

// react-carousel
import "pure-react-carousel/dist/react-carousel.es.css";

function App() {
  const { themeDirection } = useConfig();

  //useEffect(() => {
  //    const requestOptions = {
  //        method: "POST",
  //        headers: { "Content-Type": "application/json" },
  //        body: JSON.stringify({
  //            // email: "peter@oneMueller.com",
  //            // password: "PgSy0=HN4$Rkj",

  //            // email: "max@Mustermann.com",
  //            // password: "qBZv5!VmU_2cS"

  //            //  email: "peter@oneMueller.com",
  //            //    password: "PgSy0=HN4$Rkj"

  //            email: "max@Mustermann.com",
  //            password: "qBZv5!VmU_2cS"
  //        }),
  //    };

  //  fetch("https://dev2.watch2watch.eu/api/v1/account/login/token", requestOptions).then(res => {
  //    console.log(res)
  //    if (res.status === 200) {
  //      return res && res.json()
  //    } else {
  //      return false
  //    }
  //  }).then(res => {
  //    if (res) {
  //      localStorage.setItem('token', res.token);
  //    }
  //  })
  //}, [])


  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536
        }
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
    }),
    [themeDirection]
  );

  const themes: Theme = createTheme(themeOptions);

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  return (
    <ThemeProvider theme={themes}>
      <CssBaseline />
      <Locales>
        <ScrollTop>

          <>
            <Routes />
          </>

        </ScrollTop>
      </Locales>
    </ThemeProvider>
  );
}

export default App;
